import { ethers } from "ethers"
import { Updater } from "use-immer"

import Popup from "../../helper/Popup"
import { AppState, sendUpdateEvent } from "../../App"
import SecureLocalStorage from "../../helper/SecureLocalStorage"

import { MODULE_EVENTS } from "../../common/moduleEvents"
import { DBMiscSettings } from "../../common/types"
import { REQUESTER } from "../../common"

/**
 *
 */
export async function selectPrivateKey(selectedPrivateKey: string, setAppState: Updater<AppState>) {
	Popup.singleInput({
		title: "Private key",
		value: selectedPrivateKey
			? selectedPrivateKey.slice(0, 4) + "..." + selectedPrivateKey.slice(-4)
			: "",
		validator: (v) => {
			try {
				const wallet = new ethers.Wallet(v)
				return null
			} catch (e) {
				return "Invalid private key"
			}
		},
		onNewValue: (privateKey) => {
			const wallet = new ethers.Wallet(privateKey)

			SecureLocalStorage.setItem("WALLET_PRIVATE_KEY", privateKey)

			setAppState((draft) => {
				draft.selectedAccount = {
					address: wallet.address,
					privateKey: privateKey,
				}
			})
		},
		secondButtonText: "Clear auth data",
		onSecondButton: () => {
			Popup.confirm({
				title: "Clear auth data",
				text: "All locally stored account data will be cleared.<br>You will need to paste your private key again.<br>Press Yes to clear data.",
				onConfirm: () => {
					SecureLocalStorage.clear()
					window.location.reload()
				},
			})
		},
	})
}

/**
 *
 */
export function toggleGlobalAutomation(globalAutomation: DBMiscSettings["globalAutomation"]) {
	sendUpdateEvent(MODULE_EVENTS.UPDATE_MISC_SETTINGS, { updates: { globalAutomation: !globalAutomation } })
}

/**
 *
 */
export function toggleRequester(requester: REQUESTER, requesters: DBMiscSettings["requesters"]) {
	sendUpdateEvent(MODULE_EVENTS.UPDATE_MISC_SETTINGS, {
		updates: { requesters: { ...requesters, [requester]: !(requesters[requester] || false) } },
	})
}
export function toggleRequesterMode(
	requester: REQUESTER,
	requesterSettings: DBMiscSettings["requesterSettings"]
) {
	const currentValue = requesterSettings?.[requester]?.tracking || "all"
	const newValue = currentValue === "all" ? "selected" : "all"
	const newSettings = structuredClone(requesterSettings)
	newSettings[requester] = {
		...newSettings[requester],
		tracking: newValue,
	}
	sendUpdateEvent(MODULE_EVENTS.UPDATE_MISC_SETTINGS, {
		updates: { requesterSettings: newSettings },
	})
}

/**
 *
 */
export function toggleDashboardDisplayAllPinnedTokens(
	dashboardDisplayAllPinnedTokens: DBMiscSettings["dashboardDisplayAllPinnedTokens"]
) {
	sendUpdateEvent(MODULE_EVENTS.UPDATE_MISC_SETTINGS, {
		updates: { dashboardDisplayAllPinnedTokens: !dashboardDisplayAllPinnedTokens },
	})
}

/**
 *
 */
export function toggleWorkerIncludeSlippage(workerIncludeSlippage: DBMiscSettings["workerIncludeSlippage"]) {
	sendUpdateEvent(MODULE_EVENTS.UPDATE_MISC_SETTINGS, {
		updates: { workerIncludeSlippage: !workerIncludeSlippage },
	})
}

/**
 *
 */
export function setWorkerTrackingValues(workerTrackingValues: DBMiscSettings["workerTrackingValues"]) {
	Popup.singleInput({
		title: "Tracking values",
		text: "Enter comma separated values",
		value: workerTrackingValues.join(","),
		validator: (v) => {
			try {
				const splitted = v.split(",")
				for (const s of splitted) {
					const num = parseFloat(s)
					if (s !== num.toString()) {
						return `"${s}" is not a number. Separate values with "," (comma)`
					}
				}
				return ""
			} catch (e) {
				return 'Invalid scheme. Separate values with "," (comma)'
			}
		},
		onNewValue: (v) => {
			const values = v.split(",").map((s) => parseFloat(s))
			sendUpdateEvent(MODULE_EVENTS.UPDATE_MISC_SETTINGS, { updates: { workerTrackingValues: values } })
		},
	})
}
