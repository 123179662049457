import {BuildApprove, BuildBridge, BuildDex, LockedNonces, Trade,} from "../types"
import {NETWORK} from "../defaults";

/**
 *
 */
export enum TRANSACTIONS_EVENTS {
    TRADE_CREATED = "TRADE_CREATED",
    TRANSACTIONS_CREATED = "TRANSACTIONS_CREATED",
    TRANSACTIONS_OVERTAKEN = "TRANSACTIONS_OVERTAKEN",
    TRANSACTIONS_CONFIRMED = "TRANSACTIONS_CONFIRMED",
    TRADE_COMPLETED = "TRADE_COMPLETED",
    TRANSACTIONS_LIST_ARCHIVED = "TRANSACTIONS_LIST_ARCHIVED",
    TRANSACTIONS_LIST_ARCHIVED_RESPONSE = "TRANSACTIONS_LIST_ARCHIVED_RESPONSE",
    TRANSACTIONS_LIST_ARCHIVED_ITEM = "TRANSACTIONS_LIST_ARCHIVED_ITEM",
    TRANSACTIONS_LIST_ARCHIVED_ITEM_RESPONSE = "TRANSACTIONS_LIST_ARCHIVED_ITEM_RESPONSE",
    TRANSACTIONS_CREATE_DEX_TX = "TRANSACTIONS_CREATE_DEX_TX",
    TRANSACTIONS_CREATE_DEX_APPROVAL_TX = "TRANSACTIONS_CREATE_DEX_APPROVAL_TX",
    TRANSACTIONS_CREATE_BRIDGE_TX = "TRANSACTIONS_CREATE_BRIDGE_TX",
    TRANSACTIONS_CREATE_BRIDGE_APPROVAL_TX = "TRANSACTIONS_CREATE_BRIDGE_APPROVAL_TX",
    PIN_TOKEN = "PIN_TOKEN",
    PIN_TOKEN_NO_TRADE = "PIN_TOKEN_NO_TRADE",
    UPDATE_LOCKED_NONCES = "UPDATE_LOCKED_NONCES",
}

/**
 *
 */
export type TRANSACTIONS_EVENTS_PARAMS = {
    [TRANSACTIONS_EVENTS.TRADE_CREATED]: Partial<Trade>
    [TRANSACTIONS_EVENTS.TRANSACTIONS_CREATED]: Partial<Trade>
    [TRANSACTIONS_EVENTS.TRANSACTIONS_OVERTAKEN]: Partial<Trade>
    [TRANSACTIONS_EVENTS.TRANSACTIONS_CONFIRMED]: Partial<Trade>
    [TRANSACTIONS_EVENTS.TRADE_COMPLETED]: Partial<Trade>
    [TRANSACTIONS_EVENTS.TRANSACTIONS_LIST_ARCHIVED]: string
    [TRANSACTIONS_EVENTS.TRANSACTIONS_LIST_ARCHIVED_RESPONSE]: string[]
    [TRANSACTIONS_EVENTS.TRANSACTIONS_LIST_ARCHIVED_ITEM]: string
    [TRANSACTIONS_EVENTS.TRANSACTIONS_LIST_ARCHIVED_ITEM_RESPONSE]: { fileName: string; data: string }
    [TRANSACTIONS_EVENTS.TRANSACTIONS_CREATE_DEX_TX]: BuildDex
    [TRANSACTIONS_EVENTS.TRANSACTIONS_CREATE_DEX_APPROVAL_TX]: BuildApprove
    [TRANSACTIONS_EVENTS.TRANSACTIONS_CREATE_BRIDGE_TX]: BuildBridge
    [TRANSACTIONS_EVENTS.TRANSACTIONS_CREATE_BRIDGE_APPROVAL_TX]: BuildApprove
    [TRANSACTIONS_EVENTS.PIN_TOKEN]: {
        tokenName: string
        pinData: {
            networkFrom: NETWORK
            networkTo: NETWORK
            pinnedAt: number
        }
    }
    [TRANSACTIONS_EVENTS.PIN_TOKEN_NO_TRADE]: {
        tokenName: string
        pinData: {
            networkFrom: NETWORK
            networkTo: NETWORK
            pinnedAt: number
        }
    }
    [TRANSACTIONS_EVENTS.UPDATE_LOCKED_NONCES]: LockedNonces
}

// Verification that all events defined in ENUM have their params defined in type above
// 	If it throws error, it means that you forgot to add event params to type above
type _ = {
    [Property in TRANSACTIONS_EVENTS]: TRANSACTIONS_EVENTS_PARAMS[Property]
}
