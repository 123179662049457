import {DBTokens, TokenShortArrayData} from ".."
import {BRAIN_EVENTS, MODULE_EVENTS_PARAMS} from "../moduleEvents"

/**
 *
 */
export enum SOCKET_PATH {
    NODER = "noder",
    EVENTER = "eventer",
    CRONER = "croner",
    WORKER = "worker",
    AUTOMATION = "automation",
    TRANSACTIONS = "transactions",
    INTERFACE = "interface",
    MONITORING = "monitoring",
}

export enum REQUESTER {
    "1INCH" = "inch",
    PARASWAP = "paraswap",
    ZEROX = "zerox",
    KYBERSWAP = "kyberswap",
    ODOS = "odos"
}

export enum NETWORK {
    ARBITRUM = "Arbitrum",
    BASE = "Base",
    BINANCE = "Binance",
    ETHEREUM = "Ethereum",
    FANTOM = "Fantom",
    GNOSIS = "Gnosis",
    OPTIMISM = "Optimism",
    POLYGON = "Polygon",
    PULSECHAIN = "PulseChain",
    SNOWTRACE = "Snowtrace",
    SOLANA = "Solana",
    TON = "TON",
}

export enum DEX {
    Apeswap_V2 = "Apeswap_V2",
    Biswap_V2 = "Biswap_V2",
    Camelot_V2 = "Camelot_V2",
    Camelot_V3 = "Camelot_V3",
    Pancakeswap_V2 = "Pancakeswap_V2",
    Pancakeswap_V3 = "Pancakeswap_V3",
    Quickswap_V2 = "Quickswap_V2",
    Quickswap_V3 = "Quickswap_V3",
    Shibaswap_V2 = "Shibaswap_V2",
    Sushiswap_V2 = "Sushiswap_V2",
    TraderJoe_V1 = "TraderJoe_V1",
    TraderJoe_V2 = "TraderJoe_V2",
    Uniswap_V2 = "Uniswap_V2",
    Uniswap_V3 = "Uniswap_V3",
}

export enum BRIDGE {
    ANYSWAP = "AnySwap",
    MULTICHAIN = "MultiChain",
    POLYBRIDGE = "PolyBridge",
    CHAINPORT = "Chainport",
    CHAINPORTBURN = "ChainportBurn",
    CHAINPORTX = "ChainportX",
    CROSSCHAIN = "CrossChain",
    SYNAPSE = "Synapse",
    POLYGON = "PolygonBridge",
    ARBITRUM = "ArbitrumBridge",
    HELLO = "HelloBridge",
    PORTAL = "PortalBridge",
    ASTERALLY = "AsterallyBridge",
    TRANCHESS = "TranchessBridge",
    RAINI = "Raini",
    POWBLOCKS = "PowblocksBridge",
    FERRUM = "Ferrum",
    SPORES = "SPORES",
    PAID_NETWORK = "PaidNetwork",
    USH = "USH",
    STARGATE = "Stargate",
    SUPER = "SuperBridge",
    BURGERSWAP = "BurgerSwap",
    WAN = "WanBridge",
    WINKY = "WinkyverseBridge",
    CHECKDOT = "CheckDot",
    OMNI = "OmniBridge",
    DAFI = "DAFI",
    NERVE = "NerveBridge",
    LNDX = "landxBridge",
    TAOX = "TaoBridgeX",
    ZRO_BRIDGE = "ZROBridge",
    QUICK_INTEL = "quickintelBridge",
    EMPYREAL = "EmpyrealBridge",
    PRIME = "PrimeBridge",
}

export const NETWORK_NATIVE_TOKEN: {
    [networkName in NETWORK]: string /** tokenName */
} = {
    [NETWORK.ARBITRUM]: "ETH",
    [NETWORK.BASE]: "ETH",
    [NETWORK.BINANCE]: "BNB",
    [NETWORK.ETHEREUM]: "ETH",
    [NETWORK.FANTOM]: "FTM",
    [NETWORK.GNOSIS]: "XDAI",
    [NETWORK.OPTIMISM]: "ETH",
    [NETWORK.POLYGON]: "MATIC",
    [NETWORK.PULSECHAIN]: "PLS",
    [NETWORK.SNOWTRACE]: "AVAX",
    [NETWORK.SOLANA]: "SOL",
    [NETWORK.TON]: "TON",
}

export const NETWORK_NAME_TO_CHAIN_ID: {
    [networkName in NETWORK]: number // https://chainlist.org/
} = {
    [NETWORK.ARBITRUM]: 42161,
    [NETWORK.BASE]: 8453,
    [NETWORK.BINANCE]: 56,
    [NETWORK.ETHEREUM]: 1,
    [NETWORK.FANTOM]: 250,
    [NETWORK.GNOSIS]: 100,
    [NETWORK.OPTIMISM]: 10,
    [NETWORK.POLYGON]: 137,
    [NETWORK.PULSECHAIN]: 369,
    [NETWORK.SNOWTRACE]: 43114,
    [NETWORK.SOLANA]: 0,
    [NETWORK.TON]: 0,
}

export const NETWORK_EXPLORER_ADDRESS: {
    [networkName in NETWORK]: string /** explorerUrl */
} = {
    [NETWORK.ARBITRUM]: "https://arbiscan.io/",
    [NETWORK.BASE]: "https://basescan.org/",
    [NETWORK.BINANCE]: "https://bscscan.com/",
    [NETWORK.ETHEREUM]: "https://etherscan.io/",
    [NETWORK.FANTOM]: "https://ftmscan.com/",
    [NETWORK.GNOSIS]: "https://gnosisscan.io/",
    [NETWORK.OPTIMISM]: "https://optimistic.etherscan.io/",
    [NETWORK.POLYGON]: "https://polygonscan.com/",
    [NETWORK.PULSECHAIN]:
        "https://scan.mypinata.cloud/ipfs/bafybeidn64pd2u525lmoipjl4nh3ooa2imd7huionjsdepdsphl5slfowy/#/",
    [NETWORK.SNOWTRACE]: "https://snowscan.xyz/",
    [NETWORK.SOLANA]: "https://solscan.io/",
    [NETWORK.TON]: "https://tonscan.org/",
}

export const NETWORK_SOURCE_TOKEN: {
    [networkName in NETWORK]: TokenShortArrayData
} = {
    [NETWORK.ARBITRUM]: ["WETH", "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1", 18],
    [NETWORK.BASE]: ["WETH", "0x4200000000000000000000000000000000000006", 18],
    [NETWORK.BINANCE]: ["WETH", "0x2170Ed0880ac9A755fd29B2688956BD959F933F8", 18],
    [NETWORK.ETHEREUM]: ["WETH", "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", 18],
    [NETWORK.FANTOM]: ["WETH", "", 0],
    [NETWORK.GNOSIS]: ["WETH", "0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1", 18],
    [NETWORK.OPTIMISM]: ["WETH", "0x4200000000000000000000000000000000000006", 18],
    [NETWORK.POLYGON]: ["WETH", "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619", 18],
    [NETWORK.PULSECHAIN]: ["WETH", "", 0],
    [NETWORK.SNOWTRACE]: ["WETH", "0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab", 18],
    [NETWORK.SOLANA]: ["WETH", "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs", 8],
    [NETWORK.TON]: ["WETH", "", 0],
}

const baseDbTokenData = {
    active: true,
    automation: true,
    slippage: 0.01,
    comments: "",
    trackingValue: 1,
}
export const NETWORK_NATIVE_AND_WRAPPED_TOKENS: DBTokens = {
    WETH: {
        tokenName: 'WETH',
        ...baseDbTokenData,
        networkData: {
            [NETWORK.ARBITRUM]: {
                address: NETWORK_SOURCE_TOKEN[NETWORK.ARBITRUM][1],
                decimals: NETWORK_SOURCE_TOKEN[NETWORK.ARBITRUM][2],
            },
            [NETWORK.BASE]: {
                address: NETWORK_SOURCE_TOKEN[NETWORK.BASE][1],
                decimals: NETWORK_SOURCE_TOKEN[NETWORK.BASE][2],
            },
            [NETWORK.BINANCE]: {
                address: NETWORK_SOURCE_TOKEN[NETWORK.BINANCE][1],
                decimals: NETWORK_SOURCE_TOKEN[NETWORK.BINANCE][2],
            },
            [NETWORK.ETHEREUM]: {
                address: NETWORK_SOURCE_TOKEN[NETWORK.ETHEREUM][1],
                decimals: NETWORK_SOURCE_TOKEN[NETWORK.ETHEREUM][2],
            },
            [NETWORK.FANTOM]: {
                address: NETWORK_SOURCE_TOKEN[NETWORK.FANTOM][1],
                decimals: NETWORK_SOURCE_TOKEN[NETWORK.FANTOM][2],
            },
            [NETWORK.GNOSIS]: {
                address: NETWORK_SOURCE_TOKEN[NETWORK.GNOSIS][1],
                decimals: NETWORK_SOURCE_TOKEN[NETWORK.GNOSIS][2],
            },
            [NETWORK.OPTIMISM]: {
                address: NETWORK_SOURCE_TOKEN[NETWORK.OPTIMISM][1],
                decimals: NETWORK_SOURCE_TOKEN[NETWORK.OPTIMISM][2],
            },
            [NETWORK.POLYGON]: {
                address: NETWORK_SOURCE_TOKEN[NETWORK.POLYGON][1],
                decimals: NETWORK_SOURCE_TOKEN[NETWORK.POLYGON][2],
            },
            [NETWORK.PULSECHAIN]: {
                address: NETWORK_SOURCE_TOKEN[NETWORK.PULSECHAIN][1],
                decimals: NETWORK_SOURCE_TOKEN[NETWORK.PULSECHAIN][2],
            },
            [NETWORK.SNOWTRACE]: {
                address: NETWORK_SOURCE_TOKEN[NETWORK.SNOWTRACE][1],
                decimals: NETWORK_SOURCE_TOKEN[NETWORK.SNOWTRACE][2],
            },
        },
    },
    WBNB: {
        tokenName: 'WBNB',
        ...baseDbTokenData,
        networkData: {
            [NETWORK.BINANCE]: {
                address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
                decimals: 18,
            },
            [NETWORK.ETHEREUM]: {
                address: "0x418D75f65a02b3D53B2418FB8E1fe493759c7605",
                decimals: 18,
            },
            [NETWORK.POLYGON]: {
                address: "0xecdcb5b88f8e3c15f95c720c51c71c9e2080525d",
                decimals: 18,
            },
        },
    },
    WMATIC: {
        tokenName: 'WMATIC',
        ...baseDbTokenData,
        networkData: {
            [NETWORK.BINANCE]: {
                address: "0xc836d8dC361E44DbE64c4862D55BA041F88Ddd39",
                decimals: 18,
            },
            [NETWORK.POLYGON]: {
                address: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
                decimals: 18,
            },
        },
    },
    MATIC: {
        tokenName: 'MATIC',
        ...baseDbTokenData,
        networkData: {
            [NETWORK.BINANCE]: {
                address: "0xcc42724c6683b7e57334c4e856f4c9965ed682bd",
                decimals: 18,
            },
            [NETWORK.ETHEREUM]: {
                address: "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
                decimals: 18,
            },
            [NETWORK.POLYGON]: {
                address: "0x0000000000000000000000000000000000001010",
                decimals: 18,
            },
        },
    },
    ARB: {
        tokenName: 'ARB',
        ...baseDbTokenData,
        networkData: {
            [NETWORK.ARBITRUM]: {
                address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
                decimals: 18,
            },
            [NETWORK.ETHEREUM]: {
                address: "0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1",
                decimals: 18,
            },
        },
    },
}

export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000"

export const DEFAULT_BRAIN_STATE: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.BRAIN_STATE_UPDATE] = {
    workersRunning: false,
    workersStartedBy: "",
    routeSearchRunning: false,
    routeSearchProgress: {},
    pinnedTokens: {},
    pinnedTokensNoTrade: {},
    autosellPins: {},
    pendingSells: [],
    lockedNonces: {},
}
