import inch from "./inch.png"
import paraswap from "./paraswap.png"
import zerox from "./zerox.png"
import kyberswap from "./kyberswap.png"
import odos from "./odos.png"
import Default from "./Default.png"
import { REQUESTER } from "../../common"

const AGGREGATOR_IMAGES: {
	[name: string]: string
	Default: string
} = {
	[REQUESTER["1INCH"]]: inch,
	[REQUESTER.PARASWAP]: paraswap,
	[REQUESTER.ZEROX]: zerox,
	[REQUESTER.KYBERSWAP]: kyberswap,
	[REQUESTER.ODOS]: odos,

	Default: Default,
}

export function getAggregatorImage(name: string) {
	return AGGREGATOR_IMAGES[name] || AGGREGATOR_IMAGES.Default
}
