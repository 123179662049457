import React, {memo, useContext, useMemo, useState} from "react"
import {Updater} from "use-immer"

import {Col, Container, Form, Row, Stack} from "react-bootstrap"

import Header_C, {
    HeaderBlockNumSection_C,
    HeaderConnectedServicesSection_C,
    HeaderSection,
    HeaderStartStopSection_C,
} from "../../components/Header"
import Content, {ConnectedUsersBlock, ContentSection, ContentSpacer, GasBlock,} from "../../components/Content"
import Sidebar, {
    SidebarButtonToggle,
    SidebarFilterButton,
    SidebarFilterRow,
    SidebarRootNavLinks,
    SidebarSection,
} from "../../components/Sidebar"

import {AppContext, AppState} from "../../App"
import DashboardTable from "./DashboardTable"

import {
    BRAIN_EVENTS,
    DBAutomationMinCoeffRules,
    DBBridges,
    DBMiscSettings,
    DBTokens,
    filterActiveTokens,
    MODULE_EVENTS_PARAMS,
    NETWORK,
    NETWORK_EXPLORER_ADDRESS,
    NETWORK_NATIVE_TOKEN,
    NETWORK_SOURCE_TOKEN,
    REQUESTER,
    Sell,
    WORKER_EVENTS
} from "../../common"

import {
    selectPrivateKey,
    setWorkerTrackingValues,
    toggleGlobalAutomation,
    toggleRequester,
    toggleRequesterMode,
} from "./actions"

import {eye, eyeSlash} from "../../helper/svg"
import {getNetworkImage} from "../../assets/networks"
import BigNumber from "bignumber.js"
import {getAggregatorImage} from "../../assets/aggregators"

/**
 * Filter data
 */
export type DashboardFilterData = {
    displayTokens: "all" | "unhidden" | "hidden"
    dexPosition: "left" | "bottom" | "none"
    displayRoutes: "true" | "false" | "bn"
}
const defaultFilterData: DashboardFilterData = {
    displayTokens: "unhidden",
    dexPosition: "left",
    displayRoutes: "false",
}

const WorkerStatsString = memo(function WorkerStatsString() {
    const {workerStatsString, workerAnalyticsString, workerEventsString, eventerStatsString} =
        useContext(AppContext)
    const workerStatsStringSplitted = workerStatsString.split("\n")
    const workerEventsStringSplitted = workerEventsString.split("\n")
    return (
        <div style={{marginBottom: "16px"}}>
            <p style={{fontSize: "0.8em"}}>{eventerStatsString}</p>
            {workerStatsStringSplitted.map((s, i) => (
                <p key={i} style={{fontSize: "0.8em", marginBottom: "2px"}}>
                    {s}
                </p>
            ))}
            <p style={{fontSize: "0.8em"}}>{workerAnalyticsString}</p>
            {workerEventsStringSplitted.map((s, i) => (
                <p key={i} style={{fontSize: "0.8em"}}>
                    {s}
                </p>
            ))}
        </div>
    )
})

type PendingSellsProps = {
    balance: DashboardProps["balance"]
    sells: Sell[]
}
const PendingSells = memo(function PendingSells({balance, sells}: PendingSellsProps) {
    const items = sells.map((s, idx) => {
        let tokenBalance = balance[s.networkSell]?.[s.token.tokenName] ?? s.balance
        tokenBalance = BigNumber(tokenBalance).isZero() ? "0" : tokenBalance
        const sellBalance = BigNumber(s.balance).isZero() ? "0" : s.balance
        const balanceChanged = tokenBalance !== sellBalance
        return (
            <div key={s.tradeId}>
                <img
                    width="14px"
                    height="14px"
                    src={getNetworkImage(s.networkSell)}
                    alt=""
                    style={{marginRight: "4px"}}
                />
                <span style={{fontSize: "0.8em", fontWeight: 600, marginRight: "2px"}}>{s.token.tokenName}:</span>
                <span style={{
                    fontSize: "0.8em",
                    marginRight: "8px",
                    backgroundColor: balanceChanged ? "#ffcc00" : "inherit"
                }}>{tokenBalance}</span>
                {idx !== sells.length - 1 && <span style={{fontSize: "0.8em", marginRight: "8px"}}>||</span>}
            </div>
        )
    })

    return (
        <div style={{marginBottom: "16px", display: "flex", alignItems: "baseline"}}>
            <p style={{fontSize: "0.8em", marginRight: "4px"}}>[Sell Balances]: </p>
            {items.map((Item) => Item)}
        </div>
    )
})

type LockedNoncesProps = {
    nonces: { [network in NETWORK]?: number | null }
}
const LockedNonces = memo(function LockedNonces({nonces}: LockedNoncesProps) {
    const networks = Object.keys(nonces).filter(key => !!nonces[key as NETWORK])

    return (
        <div style={{marginBottom: "16px", display: "flex", alignItems: "baseline"}}>
            <p style={{fontSize: "0.8em", marginRight: "4px"}}>[Locked Nonces]: </p>
            {networks.map((network) => (
                <div key={network} style={{marginRight: "8px"}}>
                    <img
                        width="14px"
                        height="14px"
                        src={getNetworkImage(network)}
                        alt=""
                        style={{marginRight: "4px"}}
                    />
                    <span style={{
                        fontSize: "0.8em",
                        fontWeight: 600,
                        marginRight: "2px"
                    }}>{nonces[network as NETWORK]}</span>
                </div>
            ))}
        </div>
    )
})

/**
 * Dashboard page
 *  Route '/'
 */
type DashboardProps = {
    workersRunning: AppState["brainState"]["workersRunning"]
    workersStartedBy: AppState["brainState"]["workersStartedBy"]
    pinnedTokens: AppState["brainState"]["pinnedTokens"]
    pinnedTokensNoTrade: AppState["brainState"]["pinnedTokensNoTrade"]
    autosellPins: AppState["brainState"]["autosellPins"]
    newHeads: AppState["newHeads"]
    allTokens: DBTokens
    bridges: DBBridges
    trackingResults: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_ITERATION_RESULT]
    selectedAccount: AppState["selectedAccount"]
    miscSettings: AppState["miscSettings"]
    connectedUsers: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CONNECTED_USERS]
    gasEstimate: AppState["gasEstimate"]
    balance: AppState["balance"]
    automationRules?: AppState["automationRules"]
    setAppState: Updater<AppState>
}
export default memo(function Dashboard({
                                           workersRunning,
                                           workersStartedBy,
                                           pinnedTokens,
                                           pinnedTokensNoTrade,
                                           autosellPins,
                                           newHeads,
                                           allTokens,
                                           bridges,
                                           trackingResults,
                                           selectedAccount,
                                           miscSettings,
                                           connectedUsers,
                                           gasEstimate,
                                           balance,
                                           automationRules,
                                           setAppState,
                                       }: DashboardProps) {
    const {brainState: {pendingSells, lockedNonces}} = useContext(AppContext)
    const [textFilter, setTextFilter] = useState("")
    const [filterData, setFilterData] = useState<DashboardFilterData>(defaultFilterData)

    const workerTokens = useMemo(() => filterActiveTokens(allTokens), [allTokens])
    const hasLockedNonces = useMemo(() => lockedNonces !== undefined && Object.values(lockedNonces).some(n => !!n), [lockedNonces])
    const ethGasPrice = gasEstimate[NETWORK.ETHEREUM]?.gasHigh ?? -1
    const minProfit = getMinProfit(ethGasPrice, automationRules?.minCoeffRules)

    return (
        <>
            <Header_C>
                <HeaderSection>
                    <HeaderStartStopSection_C/>
                </HeaderSection>
                <HeaderSection justifyContent="center">
                    <HeaderConnectedServicesSection_C/>
                </HeaderSection>
                <HeaderSection justifyContent="end">
                    <HeaderBlockNumSection_C/>
                </HeaderSection>
            </Header_C>

            <Container fluid className="main">
                <Row>
                    <Col className="sidebarContainer">
                        <Sidebar>
                            <SidebarRootNavLinks/>

                            <SidebarAccountSection
                                selectedAccount={selectedAccount}
                                setAppState={setAppState}
                            />

                            <SidebarAutomationSection
                                globalAutomation={miscSettings?.globalAutomation}
                                dashboardDisplayAllPinnedTokens={
                                    miscSettings?.dashboardDisplayAllPinnedTokens
                                }
                            />

                            <SidebarWorkerSection
                                workerIncludeSlippage={miscSettings?.workerIncludeSlippage}
                                workerTrackingValues={miscSettings?.workerTrackingValues}
                                requesters={miscSettings?.requesters}
                                requesterSettings={miscSettings?.requesterSettings}
                            />

                            <SidebarBalanceSection balance={balance} selectedAccount={selectedAccount}/>

                            <SidebarSection name="Display settings" gap={2}>
                                <SidebarTextFilter textFilter={textFilter} setTextFilter={setTextFilter}/>
                                <SidebarDisplaySettingsSection
                                    filterData={filterData}
                                    setFilterData={setFilterData}
                                />
                            </SidebarSection>
                        </Sidebar>
                    </Col>
                    <Col className="contentContainer">
                        <Content>
                            <p>
                                🔵 Shift+click on slippage cell to set network slippage for green network.
                                Shift+click on the right side of cell to set slippage for red network.
                                <br/>
                                🔵 Cmd+Shift+click on slippage cell to set token slippage.
                                <br/>
                                🟣 Cmd+Shift+click on network cell to ignore cell from tracking.
                                <br/>
                                🟠 Shift+click on profit cell to pin token without trade creation.
                            </p>
                            {workersRunning && <p>Started by: {workersStartedBy}</p>}
                            {<WorkerStatsString/>}
                            {!!pendingSells?.length && <PendingSells balance={balance} sells={pendingSells}/>}
                            {hasLockedNonces && <LockedNonces nonces={lockedNonces}/>}
                            <ContentSection>
                                <ConnectedUsersBlock users={connectedUsers}/>
                                <GasBlock
                                    value={ethGasPrice}
                                    minProfit={minProfit}
                                    otherNetworks={Object.entries(gasEstimate)
                                        .filter(([n]) => n !== NETWORK.ETHEREUM)
                                        .map(([n, d]) => ({
                                            networkName: n,
                                            value: d.gasHigh,
                                        }))}
                                />
                            </ContentSection>
                            <ContentSection renderP>
                                <DashboardTable
                                    workersRunning={workersRunning}
                                    dashboardDisplayAllPinnedTokens={false}
                                    pinnedTokens={pinnedTokens}
                                    pinnedTokensNoTrade={pinnedTokensNoTrade}
                                    autosellPins={autosellPins}
                                    newHeads={newHeads}
                                    gasEstimate={gasEstimate}
                                    workerTokens={workerTokens}
                                    bridges={bridges}
                                    trackingResults={trackingResults}
                                    textFilter={textFilter}
                                    filterData={filterData}
                                    miscSettings={miscSettings}
                                />
                            </ContentSection>
                            <ContentSection>
                                <ContentSpacer height={100}/>
                            </ContentSection>
                        </Content>
                    </Col>
                </Row>
            </Container>
        </>
    )
})

/**
 *
 */
type SidebarAccountSectionProps = {
    selectedAccount: AppState["selectedAccount"]
    setAppState: Updater<AppState>
}
const SidebarAccountSection = memo(function GetSidebarSections({
                                                                   selectedAccount,
                                                                   setAppState,
                                                               }: SidebarAccountSectionProps) {
    return (
        <SidebarSection name="Account">
            <SidebarButtonToggle
                text="Selected"
                value={
                    selectedAccount.address
                        ? selectedAccount.address.slice(0, 6) + "..." + selectedAccount.address.slice(-4)
                        : "none"
                }
            />
            <SidebarButtonToggle
                text="Private key"
                value={
                    selectedAccount.privateKey
                        ? selectedAccount.privateKey.slice(0, 4) +
                        "..." +
                        selectedAccount.privateKey.slice(-4)
                        : "👉 select 👈"
                }
                onClick={() => selectPrivateKey(selectedAccount.privateKey, setAppState)}
            />
        </SidebarSection>
    )
})

/**
 *
 */
type SidebarAutomationSectionProps = {
    globalAutomation?: boolean
    dashboardDisplayAllPinnedTokens?: boolean
}
const SidebarAutomationSection = memo(function SidebarAutomationSection({
                                                                            globalAutomation,
                                                                            dashboardDisplayAllPinnedTokens,
                                                                        }: SidebarAutomationSectionProps) {
    if (globalAutomation === undefined) {
        // if (globalAutomation === undefined || dashboardDisplayAllPinnedTokens === undefined) {
        return (
            <SidebarSection name="Automation">
                <SidebarButtonToggle text="⏳ Loading"/>
                {/* <SidebarButtonToggle text="⏳ Loading" /> */}
            </SidebarSection>
        )
    }

    return (
        <SidebarSection name="Automation">
            <SidebarButtonToggle
                text={globalAutomation ? "🟢 Enabled" : "🔴 Disabled"}
                onClick={() => toggleGlobalAutomation(globalAutomation)}
            />
            {/* <SidebarButtonToggle
				text={dashboardDisplayAllPinnedTokens ? "📍 Display all token pins" : "📍 Display network pair pins"}
				onClick={() => toggleDashboardDisplayAllPinnedTokens(dashboardDisplayAllPinnedTokens)}
			/> */}
        </SidebarSection>
    )
})

/**
 *
 */
type SidebarWorkerSectionProps = {
    workerIncludeSlippage?: boolean
    workerTrackingValues?: number[]
    requesters?: DBMiscSettings["requesters"]
    requesterSettings?: DBMiscSettings["requesterSettings"]
}
const SidebarWorkerSection = memo(function SidebarWorkerSection({
                                                                    workerIncludeSlippage,
                                                                    workerTrackingValues,
                                                                    requesters,
                                                                    requesterSettings,
                                                                }: SidebarWorkerSectionProps) {
    if (workerIncludeSlippage === undefined || workerTrackingValues === undefined) {
        return (
            <SidebarSection name="Worker">
                <SidebarButtonToggle text="⏳ Loading"/>
            </SidebarSection>
        )
    }

    return (
        <SidebarSection name="Worker">
            {/* <SidebarButtonToggle
				text={workerIncludeSlippage ? "🟢 Include slippage" : "🔴 Exclude slippage"}
				onClick={() => toggleWorkerIncludeSlippage(workerIncludeSlippage)}
			/> */}
            <SidebarButtonToggle
                text="Tracking"
                value={workerTrackingValues.join(",")}
                onClick={() => setWorkerTrackingValues(workerTrackingValues)}
            />
            <div style={{marginTop: "4px"}}>
                {Object.values(REQUESTER).map((r) => {
                    const rTracking = requesterSettings?.[r]?.tracking || "all"
                    const rTrackingText = (() => {
                        switch (rTracking) {
                            case "all":
                                return "A"
                            case "selected":
                                return "S"
                                return "?"
                        }
                    })()
                    return (
                        <SidebarFilterButton
                            key={r}
                            style={{margin: "2px", position: "relative"}}
                            text={
                                <div>
                                    <img width={14} src={getAggregatorImage(r)}></img>
                                    <span
                                        style={{
                                            opacity: "0.6",
                                            position: "absolute",
                                            bottom: "0px",
                                            left: "0",
                                            fontSize: "0.7em",
                                        }}
                                    >
										{rTrackingText}
									</span>
                                </div>
                            }
                            active={requesters?.[r] ?? false}
                            onClick={(e) => {
                                const {shiftKey, metaKey} = e
                                if (shiftKey && metaKey) {
                                    if (requesterSettings) {
                                        toggleRequesterMode(r, requesterSettings)
                                    }
                                } else {
                                    toggleRequester(r, requesters || {})
                                }
                            }}
                        />
                    )
                })}
            </div>
        </SidebarSection>
    )
})

/**
 *
 */
type SidebarTextFilterProps = {
    textFilter: string
    setTextFilter: React.Dispatch<React.SetStateAction<string>>
}
const SidebarTextFilter = memo(function SidebarTextFilter({
                                                              textFilter,
                                                              setTextFilter,
                                                          }: SidebarTextFilterProps) {
    return (
        <Form.Control
            size="sm"
            type="text"
            placeholder="token name"
            value={textFilter}
            onChange={(e) => setTextFilter(e.target.value)}
        />
    )
})

/**
 *
 */
type SidebarBalanceSectionProps = {
    balance: AppState["balance"]
    selectedAccount: AppState["selectedAccount"]
}
const SidebarBalanceSection = memo(function SidebarBalanceSection({
                                                                      balance,
                                                                      selectedAccount,
                                                                  }: SidebarBalanceSectionProps) {
    const networkBalanceThresholds: { [network in NETWORK]?: number } = {
        [NETWORK.ARBITRUM]: 0.1,
        [NETWORK.BASE]: 0.1,
        [NETWORK.BINANCE]: 1,
        [NETWORK.ETHEREUM]: 0.3,
        [NETWORK.POLYGON]: 150,
    }
    return (
        <SidebarSection name="Balance" gap={0}>
            {Object.entries(balance)
                .sort((a, b) => a[0].localeCompare(b[0]))
                .map(([_networkName, networkBalances]) => {
                    const networkName = _networkName as NETWORK
                    const nativeBalance = BigNumber(networkBalances[NETWORK_NATIVE_TOKEN[networkName]] || 0)
                    const nativeBalanceStr = nativeBalance.toFixed(3)
                    const thresholdReached =
                        nativeBalance.comparedTo(0) !== 0 &&
                        nativeBalance.comparedTo(networkBalanceThresholds[networkName]!) === -1
                    return (
                        <SidebarButtonToggle
                            key={networkName}
                            text={
                                <>
                                    <Stack direction="horizontal" gap={1} style={{width: "100%"}}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignSelf: "stretch",
                                                paddingLeft: 4,
                                                paddingRight: 4,
                                                alignItems: "center",
                                                borderRadius: 4,
                                                backgroundColor: thresholdReached ? "#ff8686" : "transparent",
                                            }}
                                        >
                                            <img
                                                width="14px"
                                                src={getNetworkImage(networkName)}
                                                alt=""
                                                style={{marginRight: 4}}
                                            />
                                            <span>
												<span>{nativeBalanceStr}</span>
												<span style={{marginLeft: "4px", fontSize: "0.75em"}}>
													{NETWORK_NATIVE_TOKEN[networkName]}
												</span>
											</span>
                                        </div>
                                        <span style={{width: "100%", textAlign: "right"}}>
											<span>
												{BigNumber(
                                                    networkBalances[NETWORK_SOURCE_TOKEN[networkName][0]] || 0
                                                ).toFixed(3)}
											</span>
											<span style={{marginLeft: "4px", fontSize: "0.75em"}}>
												{NETWORK_SOURCE_TOKEN[networkName][0]}
											</span>
										</span>
                                    </Stack>
                                </>
                            }
                            onClick={() => {
                                window.open(
                                    `${NETWORK_EXPLORER_ADDRESS[networkName]}/address/${selectedAccount.address}`,
                                    "_blank"
                                )
                            }}
                        />
                    )
                })}
        </SidebarSection>
    )
})

/**
 *
 */
type SidebarDisplaySettingsSectionProps = {
    filterData: DashboardFilterData
    setFilterData: React.Dispatch<React.SetStateAction<DashboardFilterData>>
}
const SidebarDisplaySettingsSection = memo(function SidebarDisplaySettingsSection({
                                                                                      filterData,
                                                                                      setFilterData,
                                                                                  }: SidebarDisplaySettingsSectionProps) {
    return (
        <>
            <SidebarFilterRow text="Display">
                <SidebarFilterButton
                    text="All"
                    active={filterData.displayTokens === "all"}
                    onClick={() => setFilterData({...filterData, displayTokens: "all"})}
                />
                <SidebarFilterButton
                    text={eye as any}
                    active={filterData.displayTokens === "unhidden"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            displayTokens: "unhidden",
                        })
                    }
                />
                <SidebarFilterButton
                    text={eyeSlash as any}
                    active={filterData.displayTokens === "hidden"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            displayTokens: "hidden",
                        })
                    }
                />
            </SidebarFilterRow>
            <SidebarFilterRow text="Dex position">
                <SidebarFilterButton
                    text="Left"
                    active={filterData.dexPosition === "left"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            dexPosition: filterData.dexPosition === "left" ? "none" : "left",
                        })
                    }
                />
                <SidebarFilterButton
                    text="Bottom"
                    active={filterData.dexPosition === "bottom"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            dexPosition: filterData.dexPosition === "bottom" ? "none" : "bottom",
                        })
                    }
                />
            </SidebarFilterRow>
            <SidebarFilterRow text="Routes">
                <SidebarFilterButton
                    text="True"
                    active={filterData.displayRoutes === "true"}
                    onClick={() => setFilterData({...filterData, displayRoutes: "true"})}
                />
                <SidebarFilterButton
                    text="False"
                    active={filterData.displayRoutes === "false"}
                    onClick={() => setFilterData({...filterData, displayRoutes: "false"})}
                />
                <SidebarFilterButton
                    text="BN"
                    active={filterData.displayRoutes === "bn"}
                    onClick={() => setFilterData({...filterData, displayRoutes: "bn"})}
                />
            </SidebarFilterRow>
        </>
    )
})

type MinCoefRule = {
    gasPrice: number
    minCoeff: number
}

const getMinProfit = (ethGasPrice?: number, minCoeffRules?: DBAutomationMinCoeffRules): number => {
    if (!ethGasPrice || ethGasPrice < 0 || !minCoeffRules) {
        return -1
    }

    let rules: MinCoefRule[] = Object.keys(minCoeffRules).map((key) => {
        return {
            gasPrice: Number(key),
            minCoeff: minCoeffRules[Number(key)]!.minCoeff,
        }
    })
    rules.sort((a, b) => a.gasPrice - b.gasPrice)

    for (const {gasPrice, minCoeff} of rules || []) {
        if (ethGasPrice <= gasPrice) return minCoeff
    }

    return -1
}
