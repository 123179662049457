import {memo, useEffect} from "react"

import {useLocation, useNavigate} from "react-router-dom"
import {Button, Col, Container, Form, Row, Stack} from "react-bootstrap"

import Header_C, {
	HeaderBlockNumSection_C,
	HeaderConnectedServicesSection_C,
	HeaderSection,
	HeaderStartStopSection_C,
} from "../../components/Header"
import Content from "../../components/Content"
import Sidebar, {
	SidebarFilterButton,
	SidebarFilterRow,
	SidebarLink,
	SidebarRootNavLinks,
	SidebarSection,
} from "../../components/Sidebar"

/**
 * Filter data
 */
export type TransactionsFilterData = {
    success: "true" | "false" | "none"
    failed: "true" | "false" | "none"
    unknown: "true" | "false" | "none"
    replaced: "true" | "false" | "none"
    manual: "true" | "false" | "none"
}
export const defaultFilterData: TransactionsFilterData = {
    success: "none",
    failed: "none",
    unknown: "none",
    replaced: "none",
    manual: "none",
}

/**
 * Transactions navigation links component
 */
export function SidebarTransactionsNavLinks() {
    return (
        <SidebarSection name="Transactions navigation">
            <Stack gap={2}>
                <SidebarLink icon="transactions" text="Latest Transactions" href="/tx/latest"/>
                <SidebarLink icon="archive" text="Archived Transactions" href="/tx/archived"/>
            </Stack>
        </SidebarSection>
    )
}

/**
 * Transactions page
 *  Route '/tx'
 */
export default memo(function Transactions() {
    const location = useLocation()
    const navigate = useNavigate()

    /**
     * Redirect user to specific setting page
     */
    useEffect(() => {
        const {pathname} = location

        if (pathname.replaceAll("/", "") === "tx") {
            navigate("/tx/latest")
        }
    }, [location.pathname])

    /**
     * Render is here only to keep the sidebar with some items
     *  so that the page is not blank while user is being redirected
     */
    return (
        <>
            <Header_C>
                <HeaderSection>
                    <HeaderStartStopSection_C/>
                </HeaderSection>
                <HeaderSection justifyContent="center">
                    <HeaderConnectedServicesSection_C/>
                </HeaderSection>
                <HeaderSection justifyContent="end">
                    <HeaderBlockNumSection_C/>
                </HeaderSection>
            </Header_C>

            <Container fluid className="main">
                <Row>
                    <Col className="sidebarContainer">
                        <Sidebar>
                            <SidebarRootNavLinks/>
                            <SidebarTransactionsNavLinks/>
                        </Sidebar>
                    </Col>
                    <Col className="contentContainer">
                        <Content>
                            <Content>{/* should be empty  */}</Content>
                        </Content>
                    </Col>
                </Row>
            </Container>
        </>
    )
})

/**
 *
 */
export const SideButtons = memo(function SideButtons() {
    const navigate = useNavigate()
    return (
        <Button size="sm" variant="success" onClick={() => navigate("/tx/new")}>
            Create new transaction
        </Button>
    )
})

/**
 *
 */
type SidebarTextFilterProps = {
    textFilter: string
    setTextFilter: React.Dispatch<React.SetStateAction<string>>
}
export const SidebarTextFilter = memo(function SidebarTextFilter({
                                                                     textFilter,
                                                                     setTextFilter,
                                                                 }: SidebarTextFilterProps) {
    return (
        <Form.Control
            size="sm"
            type="text"
            placeholder="trade | hash | token | dex | bridge"
            value={textFilter}
            onChange={(e) => setTextFilter(e.target.value)}
        />
    )
})

/**
 *
 */
type SidebarPropertyFilterProps = {
    filterData: TransactionsFilterData
    setFilterData: React.Dispatch<React.SetStateAction<TransactionsFilterData>>
}
export const SidebarPropertyFilter = memo(function SidebarPropertyFilter({
                                                                             filterData,
                                                                             setFilterData,
                                                                         }: SidebarPropertyFilterProps) {
    const {success, failed, replaced, unknown, manual} = filterData

    return (
        <>
            <SidebarFilterRow text="Success">
                <SidebarFilterButton
                    text="True"
                    active={success === "true"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            success: success === "true" ? "none" : "true",
                        })
                    }
                />
                <SidebarFilterButton
                    text="False"
                    active={success === "false"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            success: success === "false" ? "none" : "false",
                        })
                    }
                />
            </SidebarFilterRow>
            <SidebarFilterRow text="Failed">
                <SidebarFilterButton
                    text="True"
                    active={failed === "true"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            failed: failed === "true" ? "none" : "true",
                        })
                    }
                />
                <SidebarFilterButton
                    text="False"
                    active={failed === "false"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            failed: failed === "false" ? "none" : "false",
                        })
                    }
                />
            </SidebarFilterRow>
            <SidebarFilterRow text="Replaced">
                <SidebarFilterButton
                    text="True"
                    active={replaced === "true"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            replaced: replaced === "true" ? "none" : "true",
                        })
                    }
                />
                <SidebarFilterButton
                    text="False"
                    active={replaced === "false"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            replaced: replaced === "false" ? "none" : "false",
                        })
                    }
                />
            </SidebarFilterRow>
            <SidebarFilterRow text="Unknown">
                <SidebarFilterButton
                    text="True"
                    active={unknown === "true"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            unknown: unknown === "true" ? "none" : "true",
                        })
                    }
                />
                <SidebarFilterButton
                    text="False"
                    active={unknown === "false"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            unknown: unknown === "false" ? "none" : "false",
                        })
                    }
                />
            </SidebarFilterRow>
            <SidebarFilterRow text="Manual">
                <SidebarFilterButton
                    text="True"
                    active={manual === "true"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            manual: manual === "true" ? "none" : "true",
                        })
                    }
                />
                <SidebarFilterButton
                    text="False"
                    active={manual === "false"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            manual: manual === "false" ? "none" : "false",
                        })
                    }
                />
            </SidebarFilterRow>
        </>
    )
})

/**
 *
 */
type SidebarClearFiltersProps = {
    textFilter: string
    filterData: TransactionsFilterData
    setTextFilter: React.Dispatch<React.SetStateAction<string>>
    setFilterData: React.Dispatch<React.SetStateAction<TransactionsFilterData>>
}
export const SidebarClearFilters = memo(function SidebarClearFilters({
                                                                         textFilter,
                                                                         filterData,
                                                                         setTextFilter,
                                                                         setFilterData,
                                                                     }: SidebarClearFiltersProps) {
    const filtersPresent =
        false || textFilter !== "" || JSON.stringify(filterData) !== JSON.stringify(defaultFilterData)

    if (!filtersPresent) return <></>

    return (
        <Button
            size="sm"
            variant="dark"
            onClick={() => {
                setTextFilter("")
                setFilterData(defaultFilterData)
            }}
        >
            Clear all filters
        </Button>
    )
})
